import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslations from "../locale/en.json";
import xhTranslations from "../locale/xh.json";
import npTranslations from "../locale/np.json";
import ybTranslations from "../locale/yb.json";
import frTranslations from "../locale/fr.json";

i18next.use(LanguageDetector).use(initReactI18next).init({
	debug: true, 
	fallbackLng: "en",
	resources: {
		en: { ...enTranslations },
		xh: { ...xhTranslations },
		np: { ...npTranslations},
		yb: { ...ybTranslations},
		fr: { ...frTranslations},
		twi: { ...xhTranslations},
	},

});