import React, {useContext, useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios'
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './WhosWatching.css'
import { AuthContext } from '../../context/AuthContext';
import { ThemeContext } from '../../context/ThemeContext'

const WhosWatchingPage = ()=>{ 
	const navigate = useNavigate()
	const location = useLocation()

	const {user, setUser} = useContext(AuthContext);
	const {darkMode, darkTheme, lightTheme} = useContext(ThemeContext)
	const theme = darkMode ? darkTheme  : lightTheme

	const [prevLogo, setLogo] = useState({image:'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619453419/AfroWatch/Afrowatch_Horizonal_Logo_-_White_cc4u6z.svg'});
	const [initialState, setState] = useState({profiles: [], message:''})
	const [profilePos, setProfPos] = useState()
	const [profileId, setProfId] = useState('')
	const [profileName, setProfName] = useState('')
	const [profileIcon, setProfImage] = useState('')
	const [currentplan, setCurrentPlan] = useState(null);
	const [isDeleteIcon, setShowDelIcon] = useState(false);
	const [alert, setAlert] = useState({message:"", isAlert:false});

	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})
	const currentUser = localStorage.getItem("currentUser");

	//location.state.profile_name

	let linkStyle ={ textDecoration: 'none',}

	const logOut= async()=>{
		const resp = await axios_.get('/auth/logout')
		console.log(resp.message);
        
		if(resp.data.success){
			setUser({});
			localStorage.clear();
			navigate('/login')
		}
	}

	const getUser = async()=>{
		const resp = await axios_.get(`/user/${currentUser}`); //profile_id
		const {data} =  resp.data;
		if(resp.data.success){
			console.log(resp.data);
			setState({...initialState, message:resp.data.message, profiles: data.profiles}) ;
			if(data.currentPlan){
				setCurrentPlan(data.currentPlan);
			}
			return resp.data
		}else{
			console.log(resp.data.message)
			setState({...initialState, message:resp.data.message, profiles: []}) 
			//navigate('/login')
		}
	}

	useEffect(()=>{
		if($(window).width() <= 600){
			setLogo({...prevLogo,image:'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1628756901/AfroWatch/Group_1_a3s59n.svg'});
		}

		if(currentUser != null){
			setUser({userId: currentUser, isUserLoggedIn: true})
		}
		getUser();
	},[])

	const deleteProfile = async ()=>{
		const resp = await axios_.put(`${currentUser}/user/profile/del`, {position: profilePos})
		if(resp.data.success){
			window.location.reload()
		}else{
			console.log(resp.data.message)
		}
	}

	const toggleShowDeleteIcon=()=>{
		setShowDelIcon(!isDeleteIcon)
	}

	const storeProfId=()=>{
		localStorage.setItem("profile_id", profileId)
		localStorage.setItem("profile_name", profileName)
		localStorage.setItem('selected_prof_id', profileId)
		
		const profileImage = initialState.profiles.find((profile) => profile._id === profileId)
		setProfImage(profileImage.profile_icon)
		localStorage.setItem('profile_icon', profileImage.profile_icon)
	}

	useEffect(()=>{
		if(location.state !== null){
			if(location.state.profile._id === profileId){
				setProfName(location.state.profile.profile_name)
				localStorage.setItem("profile_name", profileName)
			}
	
			try {
				const profiles = [...initialState.profiles];

				if(location.state.message === 'added profile'){
					profiles.push(location.state.profile);
					setState({...initialState, profiles });
				}else{
					for(const profile of profiles){
						if(profile._id === location.state.profile._id){
							profile.profile_name = location.state.profile.profile_name;
							setState({...initialState, profiles });
							return;
						}
					}	
				}	
			} catch (ex) {
				console.log(ex)
			}
		}
	},[location.state])

	// After redirecting on successful payment for subscription
	const searchQuery = useLocation().search
	const redirectStatus = new URLSearchParams(searchQuery).get("redirect_status");

	useEffect(()=>{
		if(redirectStatus === 'succeeded'){
			setAlert({isAlert:true});
			updatePlanStatus()
		}
	},[redirectStatus])
	
	const updatePlanStatus = async() =>{
		let body = {}
		const userResp = await axios_.get(`/user/${currentUser}`)
		if(userResp.data.success){
			const { data } = userResp.data
			body = {
				currentPlan:{
					id: data.currentPlan.id,
					priceId : data.currentPlan.priceId,
					subscriptionId: data.currentPlan.subscriptionId,
					status: 'active'
				}
			}

			try {
				const resp = await axios_.put(`${currentUser}/user/plan-status`, body)
				
				if(resp.data.success){
					const { data } = resp.data
					console.log(data)
					return data; // expected active
				}
			} catch (e) {
				console.log(e)	
			}
		}else{
			console.log('error getting user')
		}
	}

	useEffect(()=>{
		if(user && user.currentPlan?.periodEndDate === Date.now() && user.currentPlan?.cancelAtPeriodEnd === true){
			localStorage.removeItem('currentUser')
			navigate('/login')
		}
	},[])

	return(
		<>
			<div className="bg-whoswatching">
				<div className="container">
					<div className="row">
						<Link to="/"><img className="py-3 whos-watching-af-logo ml-4 mt-3 mt-md-0 ml-md-0" src={prevLogo.image} alt="afrowatch-logo"/></Link>

						{user.isUserLoggedIn ? <div className="col-md-8">
							<button id='log-out-btn' type="button" className="btn btn-danger" onClick={logOut}>Log out</button>
						</div>: null}
					</div>

					<div className="row mt-5 justify-content-center">
						<h1 className="text-left text-md-center whos-watching">Who's Watching?</h1>
					</div>

					<div id="user-profile-row" className="row justify-content-center">
						{initialState.profiles.length > 0   ? initialState.profiles.map((profile, pos)=>{
							return <div key={pos} className="col-6 col-md-2 text-center">
								<IconButton onMouseOver={()=>setProfPos(pos)} onClick={deleteProfile} className={isDeleteIcon ? 'showDeleteProfileIcon text-white' : 'hideDeleteProfileIcon'} aria-label="delete" size="large">
									<CancelIcon fontSize="large" />
								</IconButton>
								
								<Link style={linkStyle} onClick={storeProfId} 
									onMouseOver={()=>[setProfId(profile._id), setProfName(profile.profile_name)]} 
									onMouseDown={window.innerWidth < 500 ? ()=>[setProfId(profile._id), setProfName(profile.profile_name)]: null}  
									to={isDeleteIcon ? '/edit-profile':`/profile/${currentUser}/watch`} state={{ profile_id:profile._id }}
								>
									<div id="profile-card"  className="card ml-2 ml-md-0 mt-5">
										{/* profile.img */}
										<div key={profile._id} className='avatar-img' style={{width:'100%',height:'100%',backgroundImage:`url(${profile.profile_icon})`,backgroundSize:'cover',padding:'0px'}}>
											{
												isDeleteIcon && 
												(
													<>
														<div className='avatar-img' style={{width:'100%', height:'100%', backgroundColor:'#FFFFFF4D'}}></div>
														<img className="edit-pencil_profile_ww" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621110573/AfroWatch/Group_fibd57.svg" alt=""/>
													</>
												)
											}
										</div>                                    
									</div>
									<div className="card-title profile-name mt-md-4">{profile.profile_name}</div>
								</Link> 
							</div> 
						}) : null}
						
						{initialState.profiles.length < 5 ? 
							<div className="col-6 col-md-2 text-center">
								<Link id="profile-card"  to="/new-profile">
									<div className="mt-3 mt-md-5">
										<img className="add_profile_img" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619863222/AfroWatch/Group_36_ani5ou.svg" alt="add_profile_btn"/>
										<div className="card-title profile-name add-new-text mt-md-4 pt-md-2">Add New</div>
									</div>
								</Link>
							</div>
							:null
						}
					</div>

					<div id="profileButtons" className="row justify-content-center mt-md-5">
						<div className="col-md-12 text-center">
							<span className="mt-md-5">
								<Link onClick={toggleShowDeleteIcon} className="mng-prof-btn py-2 px-md-5 px-2" to="#">Manage</Link>
								{/* <Link className="edt-prof-btn py-2 px-md-5 px-2 ml-md-5 ml-2"  to={`/profile/${currentUser}/watch`}>Edit Profile</Link> */}
							</span>
						</div>
					</div>

					{/**Mobile */}
					<span className="profile-buttons-mobile">
						<div className="row mt-5 mb-2">
							<div className="col-md-6 text-center">
								<Link onClick={toggleShowDeleteIcon} className="mng-prof-btn py-2 py-md-1 px-md-5 px-2" to="#">Manage Profile</Link>
							</div>
						</div>

						<div className="row py-3">
							<div className="col-md-6 text-center">
								{/* <Link className="edt-prof-btn py-1 px-md-5 px-4 ml-md-5"  to="">Edit Profile</Link> */}
							</div>
						</div>
					</span>


				</div>
			</div>
			{
				alert.isAlert && <Snackbar open={alert.isAlert} autoHideDuration={6000} onClose={()=> setAlert({isAlert:false})}  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
					<Alert style={{marginBottom: '1rem'}} onClose={()=> setAlert({isAlert:false})}  icon={<CheckCircleOutlineIcon fontSize="inherit"  />} 	sx={{ width: '100%' }} color="success" severity="success"
					>
						Subscription added successfully!
					</Alert>
				</Snackbar>
			}

		</>
	)
}

export default WhosWatchingPage;