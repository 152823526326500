import {useState, useEffect, useContext} from 'react';
import { ModalContext } from '../../context/ModalContext';
import { useNavigate } from 'react-router';
import axios from 'axios'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import MovieCard from '../MoviesPage/MovieCardComponent';
import Footer from '../Footer/Footer';
import BottomNav from '../Nav/BottomNav';
import NavBar from '../Nav/TopNavBarV2';
import './style/index.css';
import { Link } from 'react-router-dom';

const ListPage =()=>{
	const navigate = useNavigate();
	const [lists, setLists] = useState([]);
	const { closeModal } = useContext(ModalContext);
	const [isFooter, setFooter] = useState({footerActive:true, bottomNavActive:false});
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL});
	const profileName = localStorage.getItem("profile_name");
	const profileIcon = localStorage.getItem("profile_icon");
	const region = localStorage.getItem("region");

	const userId = localStorage.getItem("currentUser"); //
	const profileId = localStorage.getItem("selected_prof_id");

	useEffect(()=>{
		getMyListPages();
		//Determine Mobile or not
		if($(window).width() <= 600){
			setFooter({...isFooter, footerActive:false, bottomNavActive:true})
		}else{
			setFooter({...isFooter, footerActive:true, bottomNavActive:false})
		}
	},[])

	const getMyListPages = async ()=>{
		const res = await axios_.get(`/${userId}/user/${profileId}/my-list`)
		const { data } = res.data
		if(res.status === 200){
			setLists(data.content)
		}else{
			console.log(res.data.message)
		}
	}

	const handleGoBack = () => {
		navigate(-1);
	}

	return(
		<div>
			<div className="bg-listPage" onClick={() => closeModal()}>
				<NavBar profile={profileName} profileIcon={profileIcon} region={region}/>
				<div className="container">

					<div className="d-flex mb-5 mt-5">
						<div className="mr-2">
							<ArrowBackIcon fontSize={'small'} sx={{cursor: 'pointer', color:'white'}}  onClick={handleGoBack}/>
						</div>
						<div>
							<h4 className="text-white">My List</h4>
							<div className="d-flex">
								<Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '40px', marginRight: '5px', opacity: 1,}}></Divider>
								<Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '5px', opacity: 1,}}></Divider>
							</div>
						</div>
					</div>

					<div className="row justify-content-center align-items-center">
						{lists.length > 0 ?  lists && lists.map((content, pos) => (
							<div key={pos} className="col-6 col-sm-3 col-lg-3  mb-4">
								<div className="movie-show">
									<MovieCard
										image={content.video_image_url ? content.video_image_url : 'https://d2fft6uz2yutyc.cloudfront.net/default_video_image.png'}
										content_id={content.content_id}
										actors={content.actors}
										content_name={content.content_name}
										date_uploaded={content.date_uploaded}
										genre={content.genre}
										video_path={content.video_path}
										length={content.length}
										description={content.content_description ? content.content_description : ""}
										header_image={content.video_header_image_url ? content.video_header_image_url : 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1708159982/default_content_header_uvxcnu.png'}
									/>
								</div>
							</div>
						)):
							(
								<>
									<div className='col-md-6'>
										<div className='mt-5'>
											<img className='empty_list_Box' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1717212230/Empty-amico_1_kfwaoz.png" alt='empty_list_box'/>
											<h4 className='text-center pt-5 pb-2 watchlist_Text text-white'>Your Watchlist list is currently empty. </h4>
											<div className='text-center py-2 creating_watchlist_Text text-white'>Start creating a Watchlist</div>
											<Link className="home-btn py-2 px-5 my-3 text-white" to={`/profile/${userId}/watch/`} activeClassName="active">Start Creating</Link>
										</div>
									</div>
								</>
							)
						}

					</div>
				</div>
			</div>
			{isFooter.footerActive ? <Footer/> : <BottomNav className="d-block d-md-none d-sm-block"/>}
		</div>
	)
}

export default ListPage;
