import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import ExplorePlans from './ExplorePlans';
import './CurrentPlan.css';
import { SUBSCRIPTION_INCOMPLETE } from '../constants';


const CurrentPlan=()=>{
	const [plan, setPlan] = useState({})
	const [user, setUser] = useState({})
	const [subscription, setSub] = useState()
	const [explore, setExplore] = useState(false);
	const [changePlan, setChangePlan] = useState(false);
	const [cards, setCards] = useState({})
	const [cancelMessage, setMessage] = useState("")
	const [snackOpen, setSnackOpen] = useState(false);
	const [open, setOpen] = React.useState(false);


	const currentUser = localStorage.getItem("currentUser");
	const currentProfile = localStorage.getItem("profile_name");
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})
	const navigate = useNavigate()

	const getUser = async()=>{
		const resp = await axios_.get(`/user/${currentUser}`)
		const {data} =  resp.data;
		if(resp.data.success){
			return data
		}else{
			console.log(resp.data.message)
		}
	}

	const getPlan = async ()=>{
		const user = await getUser()
		setUser(user)
		if (user.currentPlan){
			const res = await axios_.get(`/plan/${user.currentPlan['id']}`)
			if(res.status === 200){
				setPlan(res.data.plan)
			}else{
				console.log('The plan does not exists')
			}

			const resp_two = await axios_.get(`/user/stripe/${currentUser}/${user.currentPlan.subscriptionId}`)
			if(resp_two.data.status === 200){
				setSub(resp_two.data.sub)
			}else{
				console.log(resp_two.data.message)
			}
		}else{
			setPlan({
				subscription_name: 'no plan',
				price: 0
			})
		}
	}

	const getUserCards = async()=>{
		if(user.length > 0){
			console.log(user)
		}

		try{
			if(user.stripeCustomerId){
				const res = await axios_.get(`/${user.stripeCustomerId}/stripe/cards`)
				if(res.status === 200){
					setCards(res.data)
				}else{
					console.log('didnt get cards')
				}
			}
		}catch(e){
			console.log('The stripe customer might not exists or is wrong')
		}
	}

	useEffect(()=>{
		getUser()
		getPlan()
	},[])

	useEffect(()=>{
		getUserCards()
	},[user])

	const toggleExplore=()=>{
		if(explore || changePlan){
			setExplore(false);
			setChangePlan(false);
		}else{
			setExplore(true);
			setChangePlan(true);
		}
	}
	
	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClose = () => {
		setOpen(false);
	};

	/**Cancel Subscription */ 
	const cancelSub= async(e)=>{
		try{
			const res = await axios_.post(`/cancel-subscription/${currentUser}`, { currentPlan: { subscriptionId: user.currentPlan.subscriptionId }})
			if(res.data.status === 200 && res.data.end === Date.now()){
				setMessage(res.data.message)
				setSub(res.data.sub)
				setSnackOpen(true)
				setTimeout(()=>{
					localStorage.removeItem('currentUser')
					navigate('/login')
				}, 3000)
			}else{
				setMessage(res.data.message)
				setSub(res.data.sub)
				setSnackOpen(true)
			}
		}catch(err){
			console.log(err)
			setMessage('Error. no plan yet')
			setSnackOpen(true)
		}
	}

	const handleSnackBarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackOpen(false);
	};

	const action = (
		<React.Fragment>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	const changePlans = () => {
		setChangePlan(true)
	}

	return(
		<>
			<>
				{explore || changePlan ? <ExplorePlans changePlan={changePlan} toggleExplore={toggleExplore} userId={user._id} user={user}  current={user.currentPlan} currentPlan={plan.subscription_tier} /> :
					<div style={{border:'1px solid #C92941', width:'100%', borderRadius:'10px', background:'#1F0F25', height:'100%'}} className="card mt-5 pb-3 pb-md-0">
						<div className="col-md-12">
							<>

								<div className="d-flex  mt-3">
									<div>
										<h4 className="text-white">Subscription</h4>
									</div>
								</div>

								{
									plan.subscription_name !== 'no plan' && <p className="mt-3 sub-labels">Transaction ID- {user && user.currentPlan ? user.currentPlan.subscriptionId.split('_')[1] : null} </p>
								}

								{
									plan.subscription_name !== 'no plan' && (
										<div className="d-block d-md-flex justify-content-between ">
											<div>
												<p className="mt-3 sub-labels">Current plan</p>
												<Link to="/pricing-plan" target='_blank' style={{fontWeight: 'bold',fontSize: '14px',lineHeight: '5px',color: '#C92941'}}>{`${plan && plan.subscription_tier  ? plan.subscription_tier : 'no plan'} for $ ${plan && plan.price ? plan.price : 0}/month`}</Link>
											</div>

											<div>
												<p className="sub-labels mt-3">Next Due date:</p>
												<p style={{color:'#FFFFFF', fontSize: '14px',lineHeight: '5px'}}>{subscription ? new Date(subscription.current_period_end * 1000).toDateString() : ""}</p>
											</div>
										</div>
									)
								}

								{user && user.profiles && user.profiles.length > 0 ?
									(user.profiles[0].profile_name === currentProfile ?
										<div className="sub-btn pb-2 pb-md-5">
											{
												plan.subscription_name === 'no plan' ? 
													<button className="explore-btn d-block py-2 px-3  mt-5" onClick={toggleExplore}>Explore Plans</button>
													:
													<button className="change-btn d-block py-2 px-4 mt-5 mr-0 mr-md-3" onClick={changePlans}>Change Plans</button>
											}
		
										</div>
										: null
									) 
									: null
								}
								{user && user.profiles && user.profiles.length > 0  ?
									(user.profiles[0].profile_name === currentProfile && user.currentPlan && user.currentPlan.status !== SUBSCRIPTION_INCOMPLETE  ?
										<div className="mt-20 d-block d-md-flex justify-content-end pb-5">
											<button onClick={handleClickOpen} className='cancel-sub-btn py-2 px-3'>Cancel Subscription</button>
										</div> : null
									) 
									: null
								}
							</> 
							
							{snackOpen ? 
								<Snackbar open={snackOpen} autoHideDuration={8000} onClose={handleSnackBarClose} message={cancelMessage} action={action}   anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
									<Alert style={{marginBottom: '1rem'}} icon={<CheckCircleOutlineIcon fontSize="inherit" />} 	sx={{ width: '100%' }} severity="success"
										onClose={handleSnackBarClose}>
										{cancelMessage}
									</Alert>
								</Snackbar> : null
							}
							
							{
								open &&
									<Dialog
										open={open}
										onClose={handleClose}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
									>
										<DialogTitle id="alert-dialog-title">{"Cancel Subscription"}</DialogTitle>

										<DialogContent>
											<Alert severity="warning">Are you sure you want to cancel this subscription ? <br/> 
												<b> Note: This action cannot be undone</b> 
											</Alert>
										</DialogContent>

										<DialogActions>
											<Button className="cancel-dialog" onClick={handleClose}>Close</Button>
											<Button className="cancel-dialog" onClick={cancelSub} autoFocus>Cancel Subscription</Button>
										</DialogActions>

									</Dialog>
							}
						</div>
					</div>
				}
			</>
		</>
	)
}

export default CurrentPlan;