import { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';
import axios from 'axios';
import Footer from '../Footer/Footer';
import BottomNav from '../Nav/BottomNav';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import './Downloads.css'
import NavBar from '../Nav/TopNavBarV2';

const DownloadsPage = () => {
	const [isFooter, setFooter] = useState({ footerActive: true, bottomNavActive: false });
	const [isNavBarSearch, setNavBarSearch] = useState({ active: true });
	const { closeModal } = useContext(ModalContext);

	const axios_ = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
	const profileName = localStorage.getItem("profile_name");
	const profileIcon = localStorage.getItem("profile_icon");
	const region = localStorage.getItem("region");

	useEffect(() => {
		//Determine Mobile or not
		if($(window).width() <= 600){
			setFooter({...isFooter, footerActive:false, bottomNavActive:true})
		}else{
			setFooter({...isFooter, footerActive:true, bottomNavActive:false})
		}
	}, []);

	const getDownloads=()=>{

	}

	const previousLocation = () => {
		navigate(-1)
	}

	return (
		<>
			<div className="bg-downloadsPage" onClick={() => closeModal()}>
				<NavBar profile={profileName} profileIcon={profileIcon} region={region}/>

				<div className="container">
					<div className="d-flex mb-5 mt-5">
						<div className="mr-2">
							<ArrowBackIcon fontSize={'small'} style={{cursor: 'pointer', color: 'white'}} onClick={previousLocation}/>		
						</div>
						<div>
							<h3 className="text-white">Downloads</h3>
							<div className="d-flex">
								<Divider textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '40px', marginRight: '5px', opacity: 1,}}></Divider>
								<Divider textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '5px', opacity: 1,}}></Divider>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<div className='row'>
						<div className='col-md-12'>
							<div className='mt-5'>
								<h4 className="text-white">
									You currently have no downloads, we will be releasing downloads soon
								</h4>
							</div>
							
							<div className='mt-4 text-white'>
								<h4>Thank you for your patience!</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isFooter.footerActive ? <Footer /> : <BottomNav className="d-block d-md-none d-sm-block" />}
		</>
	);
};

export default DownloadsPage;
