import React, {createContext, useState} from 'react'

export const ThemeContext = createContext()

const ThemeContextProvider=(props)=>{
	const [darkMode, setDarkMode] = useState(false);
	const darkTheme = {
		background: '#2D132C', 
		text: 'white'
	}

	const lightTheme = {
		background: 'rgb(50, 29, 49)',
		text: '#C92941'
	}

	const toggleTheme = () => {
		setDarkMode(!darkMode);
	};

	return (
		<div>  
			<ThemeContext.Provider value={{darkMode, toggleTheme, darkTheme, lightTheme}}>
				{props.children}
			</ThemeContext.Provider>
		</div>
	)
}

export default ThemeContextProvider