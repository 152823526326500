module.exports = {
	GENRES :  [
		'Action',
		'Drama',
		'Afrime',
		'Adventure',
		'Biography',
		'Comedy',
		'Documentary',
		'Family',
		'Horror',
		'Musical',
		'Mystery',
		'Political',
		'Romance',
		'Sci-fi',
		'Sports',
		'Suspense',
		'Teen',
		'Thriller',
		'War',
	],

	CORRECT_PASSWORD:"Password reset successfully.",
	VIDEO_PLAYER_OPTIONS:{
		autoplay: false,
		controls: true,
		enableDocumentPictureInPicture: true,
		fill: true,
		responsive: true,
		aspectRatio: '16:9',
		playbackRates: [0.5, 1, 1.5, 2],
		// fluid: true,
		html5: {
			nativeTextTracks: false,
			// nativeControlsForTouch: true
		},
		controlBar: {
			skipButtons: {
				forward: 10,
				backward: 10
			},
		},
		userActions: {
			hotkeys: {
				playPauseKey: function () {
					return (event.which === 32 || event.which === 75);// SPACE BAR OR K KEY
				},
				muteKey: function (event) {
					return (event.which === 77);// M KEY
				},
				fullscreenKey: function (event) {
					return (event.which === 70); // F KEY
				}
			}
		}
	},
	PLAYBACK_TRACKING_OPTS:{
		playbackDataUpdateInterval: 15 * 1000, // 15 seconds
		playbackDataUploadInterval: 60 * 1000 // 1 minute
	},

	SUBSCRIPTION_INCOMPLETE : 'incomplete' || 'incomplete_expired ' || 'requires_payment_method'
}