import {useEffect,useState} from 'react';
import ProfileIcon from './ProfileIcon';

import axios from 'axios'

const EditProfile=()=>{
	const currentUser = localStorage.getItem('currentUser');
	const currentProfile = localStorage.getItem("profile_name");

	const [profileState, setProfile] = useState({fullName:currentProfile,id:'', email:''});
	const [user_, setUser] = useState()
	const [profileNames,setprofileNames] = useState([])
	const [profileIcons,setprofileIcons] = useState([])
	const [profileIconFormActive,setProfileIconForm] = useState(false)
	const [buttonClass,setButtonClass] = useState({show:{}})

	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

	const getUser = async()=>{
		const resp = await axios_.get(`/user/${currentUser}`)
		const {data} =  resp.data;
		if(resp.data.success){
			setUser(data)
		}else{
			console.log(resp.data.message)
		}
	}


	const updateProfileName= async (e,index,profile_id)=>{
		e.preventDefault()
		try{
			let fullName = profileNames[index];

			const body = {
				fullName: fullName.trim(),
			}

			const resp = await axios_.put(`/${currentUser}/user/${profile_id}`, body)
			console.log(resp.data);

			if(resp.data.success){
				const {data} = resp.data
				console.log(data);
				setProfile({...profileState, firstName:data.profile_name})

			}else{
				console.log(resp.data.message)
			}
		}catch(e){
			console.log(e)
		}
	}

	const updateProfileIcon= async (e,index,profile_id)=>{
		e.preventDefault()
		try{
			const resp = await axios_.put(`/${currentUser}/user/${profile_id}/icon`, {icon:profileIcons[index]})
			console.log(resp.data);
			if(resp.data.success){
				console.log('updated successfully')
			}else{
				console.log(resp.data.message)
			}

		}catch(e){
			console.log(e)
		}
	}

	const showUpdateButton = (index,status=true) =>{
		const showObj = buttonClass.show;	
		showObj[index] = status;
		setButtonClass({show:showObj});
	}

	const updateprofileNameArray = (index,inputValue)=>{
		const profileNameArray = profileNames;
		profileNameArray[index] = inputValue;		
		setprofileNames([...profileNameArray]);
		showUpdateButton(index);
	};

	const updateprofileIconArray = (index,inputValue)=>{
		const profileIconArray = profileIcons;
		profileIconArray[index] = inputValue;		
		setprofileIcons([...profileIconArray]);
		showUpdateButton(index);
	};

	useEffect(()=>{
		getUser()
	},[])

	const updateProfile= async (e,index,profile_id)=>{
		if (profileNames[index]) await updateProfileName(e,index,profile_id);
		if (profileIcons[index]) await updateProfileIcon(e,index,profile_id);
		showUpdateButton(index,false);
	}

	return(
		<div className="container">
			{/* <div className="row mt-5"> */}
			{/* <div className="col-md-12">  */}
			{/* <form id="uploadImage" action="" method="post">
				<label htmlFor="file-input">
					<img className="profile-picture mr-md-3" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620996222/AfroWatch/Montreal-personal-branding-linkedin-profile-professional-headshot-by-nadia-zheng-800x1000_1_esxdqb.svg" alt=""/>
					<img className="edit-pencil" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621110573/AfroWatch/Group_fibd57.svg" alt=""/>
				</label>
				<input id="file-input" type="file"/> 
			</form>*/}
			<div className="row mt-5 d-flex">
				<div className="p-3 col-md-9">
					{
						user_ ?
							(user_.profiles[0].profile_name === currentProfile ?
								user_.profiles.map((profile, pos)=>{
									return <div key={pos} className='my-1'>
										<p className='text-white'>Profile {pos + 1}</p>
										<form className='d-flex align-items-center flex-wrap' onSubmit={(e)=>{updateProfile(e, pos, profile._id)}} action="" method="post">
											<input id={profile._id} className="form-control my-3 col-8 col-md-6" type="text" name="firstName" onChange={(e)=>{updateprofileNameArray(pos,e.target.value)}} value={profileNames[pos] ? profileNames[pos]:profile.profile_name} placeholder="First name" required/>
											{/* <img className='ml-3 mt-2' style={{width:'50px', height:'50px', borderRadius:'10px'}} src={profile.profile_icon} alt=""/> */}
											<ProfileIcon profile_id={profile._id} profile_icon={profileIcons[pos] ? profileIcons[pos] : profile.profile_icon} setprofile_icon={(icon)=>{updateprofileIconArray(pos,icon)}} switch={profileIconFormActive} setSwitch={()=>{profileIconFormActive ? setProfileIconForm(false):setProfileIconForm(true)}}/>
											<button type='submit' className={buttonClass.show[pos] ? 'btn btn-danger btn-sm col-md-2 ml-md-5 my-1':'d-none'}>Update</button>
										</form>
									</div>
								}):
								user_.profiles.map((profile)=>{
									return  <input onChange={(e)=>setProfile({...profileState, firstName:e.target.value})} style={{backgroundColor:'inherit', color:'white'}} className="form-control my-md-3" type="text" name="firstName" defaultValue={currentProfile ? profile.profile_name : ""}  placeholder="First name" required disabled/>
								})
							)
							: null
					}
				</div>
			</div>
		</div>
	)
}

export default EditProfile;