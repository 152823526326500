import React,{useState,useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {BottomNavigation, BottomNavigationAction} from '@mui/material';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AddIcon from '@mui/icons-material/Add';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import SystemUpdateAltRoundedIcon from '@mui/icons-material/SystemUpdateAltRounded';
import SlowMotionVideoOutlinedIcon from '@mui/icons-material/SlowMotionVideoOutlined';
import './BottomNav.css'


const BottomNav=(props)=>{
	const[navValue, setValue] = useState(0)

	const navigate = useNavigate()
	const location = useLocation()
	const currentUser = localStorage.getItem('currentUser')

	// Images were exported with text so no labelling
	console.log(location)
	return(
		<BottomNavigation showLabels value={navValue} onChange={(event, newValue) => {() => setValue(newValue)}} className="bottom-nav" style={{bottom:location.pathname == '/my-list' || location.pathname == '/downloads' ? '0px':'', color: 'white'}} >
			{/* <img src={icons.iconHome} alt="icon_home" className="bottom-nav-icon-img"/> */}
			<BottomNavigationAction label="Home"  icon={<HomeRoundedIcon  style={{color: 'white'}}/>} onClick={() => navigate(`/profile/${currentUser}/watch`)}/>
			<BottomNavigationAction label="New" icon={<SystemUpdateAltRoundedIcon  style={{color: 'white'}}/>} onClick={() => navigate(`/new-arrivals`)}/>
			{/* <img src={icons.iconArrived} alt="icon_arrived" className="bottom-nav-icon-img-arrived"/> */}
			<BottomNavigationAction label="My List" icon={<AddIcon  style={{color: 'white'}}/>} className="bottom-nav-icon-img" onClick={() => navigate(`/my-list`)}/>
			<BottomNavigationAction label="Downloads" icon={<DownloadRoundedIcon  style={{color: 'white'}}/>} onClick={() => navigate(`/downloads`)}/>
			<BottomNavigationAction label="AfClips" icon={<SlowMotionVideoOutlinedIcon  style={{color: 'white'}}/>} onClick={() => navigate(`/afclips`)}/>
		</BottomNavigation>
	)


}
export default BottomNav;