import React, {useEffect, useState, useLayoutEffect} from 'react';
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import {Link, useNavigate} from 'react-router-dom';
import './ModalCountrySelection.css'

const ModalCountrySelection=(props)=>{
	const releaseType = ['coming soon', 'released']
	const currentUser = localStorage.getItem("currentUser");
	const navigate = useNavigate()
    
	const[countryCardTheme, setCountryCard] = useState({background: '#C92941', isHovered:false})
	const[afrimeCardTheme, setAfrimeCard]= useState({isHovered:false,  background:'url(https://images.unsplash.com/photo-1547891654-e66ed7ebb968?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80)'})
	const[region, _setRegion] = useState('')
	const[modalState, setShowModal] = useState({isCountryModal:true})

	const[initialModalState, setModalState]=useState([{
		id: uuidv4(),
		name: 'Nigeria',
		released: releaseType[0],
		isCountryCard: true,
		link:`#`, ///profile/${currentUser}/watch/Nigeria
		prompt:{message:'Coming soon', isShown:false}
	},
	{
		id: uuidv4(),
		name: 'Ghana',
		released: releaseType[0],
		isCountryCard: true,
		link:'#',
		prompt:{message:'Coming soon', isShown:false}
	},
	{
		id: uuidv4(),
		name: 'South Africa',
		released: releaseType[0],
		isCountryCard: true,
		link:'#',
		prompt:{message:'Coming soon', isShown:false}
	},
	{
		id: uuidv4(),
		name: 'Kids Afrime',
		released: releaseType[1],
		isCountryCard: false,
		link:`/profile/${currentUser}/watch/Afrime`,
		prompt:{message:'Coming soon', isShown:false}
	}])

	useLayoutEffect(() => {
		const originalStyle = window.getComputedStyle(document.body).overflow;
		document.body.style.overflow = "hidden";
		return () => (document.body.style.overflow = originalStyle);
	}, []);

	const setRegion=()=>{
		localStorage.removeItem('region')
		localStorage.setItem('region', region)
		if(region == "Nigeria"){
			navigate(`/profile/${currentUser}/watch/`)
		}else{
			navigate(`/profile/${currentUser}/watch/`)
		}

		// make request to get content based on region
		// getContentByCountry()
	}

	const getContentByCountry= async ()=>{
		const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})
		const res = await axios_.get(`/content/${region}`)
		if(res.data.status === 200){
			console.log(res.data.content)
		}
	}
	useEffect(()=>{
		console.log(region)
		setRegion()

	},[region])

	return (
		<div style={{
			position: "fixed",
			width: "100vw",
			height: "100vh",
			left: "0",
			top: "0",
			zIndex: 1500
		}}>
			<div className="modal-backdrop fade show" onClick={props.closed}/>
			<div className="container">
				<div className="modal-dialog" style={{maxWidth: "100%"}}>
					<div className="modal-content px-4 pb-5 pb-md-0" style={{zIndex: 1550}}>
						<div className="modal-body">
							<div className="row mt-5">
								<div className="col-md-12">
									<h5 className="modal-title text-center">Choose your country</h5>
									{/* <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> */}
								</div>
							</div>


							<div className="row mt-5">
								{initialModalState.map((country)=>{
									const theme = country.isCountryCard ? countryCardTheme.background : afrimeCardTheme.background
																	
									return <div key={uuidv4()} className="col-12 col-md-3">
										{/* Relative Link */}
										{/* Country name nigeria show nollywood modal */}
										<Link onClick={()=>_setRegion(country.name)} style={{textDecoration: 'none'}} to={country.link}>
											<div className="card country-card my-2 my-md-0 py-1 py-md-0" style={{background: theme, backgroundSize:'cover'}}>
												<div className="card-body">
													<p className="text-center modal-text py-md-5">{country.name}</p>
													{country.prompt.isShown ? <p className="text-center prompt">{country.prompt.message}</p> : null}
												</div>  
											</div>
										</Link>
									</div>
																	
								})} 

								{/* {modalState.isNollyWoodModal ? <ModalNollyWoodCategory/> : null} */}
							</div>

							<div className="row">
								<div className="col-md-12">
									<button type="button" className="close mt-3" data-dismiss="modal" aria-label="Close" onClick={props.closed}><span aria-hidden="true">Done</span></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ModalCountrySelection;